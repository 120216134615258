
import { Component, Prop, PropSync, Ref, Watch } from "vue-property-decorator";
import SnackbarModule from "@/store/snackbarModule";
import { getModule } from "vuex-module-decorators";
import Validation from "@/mixins/validation";
import { VForm } from "@/types/vForm";
import { UserUpdateModel, ConstantModel, VenueModel } from "@/api/generated";
import api from "@/api";
import UserModule from "@/store/userModule";
import userRoleConstant from "@/Constants/userRoleConstant";

const snackbarModule = getModule(SnackbarModule);
const userModule = getModule(UserModule);
@Component
export default class UserUpdateDialog extends Validation {
  @PropSync("dialog", { type: Boolean }) syncedDialog!: boolean;
  @Prop(String) userId!: string;
  @Ref() private readonly form!: VForm;

  private loading = false;
  private userRoles: Array<ConstantModel> = [];
  private venues: Array<VenueModel> = [];
  private model: UserUpdateModel = {
    firstname: "",
    lastname: "",
    role: "",
  };

  @Watch("syncedDialog")
  private async onDialogClose() {
    if (!this.syncedDialog) {
      this.form?.reset();
    }

    const response = await api.UserService.apiUserUserIdGet(this.userId);
    this.model = response.data;
  }

  private async created() {
    this.getUserRoles();
    this.getUserById();
    this.getVenues();
  }

  private get superAdminPermissions() {
    return (
      userModule.userRole == userRoleConstant.systemAdministrator ||
      userModule.userRole == userRoleConstant.operationsManager ||
      userModule.userRole == userRoleConstant.customerAdmin
    );
  }

  private async getUserById() {
    const response = await api.UserService.apiUserUserIdGet(this.userId);
    this.model = response.data;
  }

  private async getUserRoles() {
    const response = await api.ConstantService.userrolesGet();
    this.userRoles = response.data;
  }

  get getFilteredUserRoles() {
    if (this.userRoles.length === 0) {
      return [];
    }

    const roleFilters = {
      [userRoleConstant.customerAdmin]: [
        userRoleConstant.porfolioManager,
        userRoleConstant.venueManager,
        userRoleConstant.customerAdmin,
      ],
    };

    const allowedRoles = roleFilters[userModule.userRole];
    // If allowed roles are defined, filter the user roles accordingly
    if (allowedRoles) {
      return this.userRoles.filter((role) =>
        allowedRoles.includes(role.value as string)
      );
    }
    // If no specific role filters are defined, return all user roles
    return this.userRoles;
  }

  private async getVenues() {
    const response = await api.VenueService.apiVenueGet();
    this.venues = response.data;
  }

  private async onUserUpdate() {
    if (!this.form?.validate()) {
      return;
    }

    try {
      this.loading = true;
      await api.UserService.apiUserUserIdPut(this.userId, this.model);
      snackbarModule.setSnackbarMessage("User updated");
      this.$emit("updated");
    } catch {
      snackbarModule.setSnackbarMessage("Failed to update user");
    } finally {
      this.loading = false;
      this.syncedDialog = false;
    }
  }

  get filteredVenues(): Array<VenueModel> {
    if (this.venues.length > 0) {
      return this.venues.filter((x) => x.archived == false);
    }
    return [];
  }
}
