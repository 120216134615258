
import { UserModel } from "@/api/generated";
import { Vue, Component } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { DataTableHeader } from "vuetify";
import nameof from "@/utility/nameof";
import api from "@/api";
import SnackbarModule from "@/store/snackbarModule";
import UserModule from "@/store/userModule";
import userRoleConstant from "@/Constants/userRoleConstant";
import EbarProfile from "@/views/ebar/EbarProfile.vue";

const snackbarModule = getModule(SnackbarModule);
const userModule = getModule(UserModule);

@Component
export default class UserTable extends Vue {
  private loading = false;
  private items: Array<UserModel> = [];
  private headers: Array<DataTableHeader<UserModel>> = [
    { text: "", value: "actions", sortable: false },
    { text: "Name", value: nameof<UserModel>("fullname") },
    { text: "Email", value: nameof<UserModel>("emailAddress") },
    { text: "Organisation", value: nameof<UserModel>("organisationName") },
    { text: "Venue", value: nameof<UserModel>("venueName") },
    { text: "Role", value: nameof<UserModel>("roleDescription") },
  ];

  private async created() {
    await this.getUsers();
  }

  private get adminOnsiteUserPermissions() {
    return (
      userModule.userRole == userRoleConstant.systemAdministrator ||
      userModule.userRole == userRoleConstant.operationsManager ||
      userModule.userRole == userRoleConstant.onSiteUser
    );
  }

  private get onSiteUserPermissions() {
    return userModule.userRole == userRoleConstant.onSiteUser;
  }

  private get portfolioManagerPermissions() {
    return userModule.userRole == userRoleConstant.porfolioManager;
  }

  private get customerAdminPermissions() {
    return userModule.userRole == userRoleConstant.customerAdmin;
  }

  private async getUsers() {
    try {
      this.loading = true;
      if (this.adminOnsiteUserPermissions) {
        const response = await api.UserService.apiUserGet();
        this.items = response.data;
      }
      if (this.portfolioManagerPermissions || this.customerAdminPermissions) {
        const response =
          await api.UserService.apiUserOrganisationusersOrganisationIdGet(
            userModule.organisationId
          );
        this.items = response.data;
      }
    } catch {
      snackbarModule.setSnackbarMessage("Failed to retrieve users");
    } finally {
      this.loading = false;
    }
  }
}
