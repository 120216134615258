
import { Vue, Component } from "vue-property-decorator";
import UserTable from "@/components/user/UserTable.vue";
import UserCreateDialog from "@/components/dialogs/user/UserCreateDialog.vue";
import UserUpdateDialog from "@/components/dialogs/user/UserUpdateDialog.vue";
import { UserModel } from "@/api/generated";
import api from "@/api";
import { getModule } from "vuex-module-decorators";
import SnackbarModule from "@/store/snackbarModule";
import UserModule from "@/store/userModule";

const snackbarModule = getModule(SnackbarModule);
const userModule = getModule(UserModule);

@Component({
  components: {
    UserTable,
    UserCreateDialog,
    UserUpdateDialog,
  },
})
export default class List extends Vue {
  private tableRefreshKey = false;
  private createDialog = false;
  private updateDialog = false;
  private deleteDialog = false;
  private selectedUserId = "";

  private async onDeleteRequest(user: UserModel) {
    if (!user.userId) {
      return;
    }
    this.selectedUserId = user.userId;
    this.deleteDialog = true;
  }

  private async onDeleteUser() {
    if (!this.selectedUserId) {
      return;
    }
    if (userModule.userId === this.selectedUserId) {
      this.deleteDialog = false;
      snackbarModule.setSnackbarMessage(
        "Delete Failed: Cannot delete yourself while you are logged in"
      );
      return;
    }
    try {
      await api.UserService.apiUserUserIdDelete(this.selectedUserId);
      snackbarModule.setSnackbarMessage(`User successfully deleted`);
      this.tableRefreshKey = !this.tableRefreshKey;
      this.$emit("deleted");
    } catch {
      snackbarModule.setSnackbarMessage("Failed to delete user");
    }
  }

  private onUpdateUser(item: UserModel) {
    if (!item.userId) {
      return;
    }

    this.selectedUserId = item.userId;
    this.updateDialog = true;
  }
}
